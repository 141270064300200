
.form-control {
  width:100%;
  display:flex;
  position:relative;
}

.form-control input,
.form-control textarea {
  width:100%;
  color:#FF2E4C;
  padding:1rem ;
  font-size:1.2rem;
  font-weight:bold;
  border:1px solid #FF2E4C;
  font-family: sans-serif;

}
input[type=checkbox] { width: auto }
.form-control label {
  display: block;
  text-align: left;
  margin-bottom: 0.5rem;
}

.form-control input
 {
  height:2rem;
}
.form-control textarea{
  height:6rem;

}
.form-control input::placeholder,
.form-control textarea::placeholder{
  color:#FF2E4C;
  font-weight:lighter;
}
.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  
  border-color: #FFE720;
}
.form-control--invalid p{
  position: absolute;
  bottom:-0.5rem;

  right:6px;
  font-size:0.8em;
}
.form-control--invalid label,
.form-control--invalid p {
  color:#FF2E4C;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: #FF2E4C;
  background: #ffd1d1 !important;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  border: 1px solid #FF2E4C;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}