.button {
  font: inherit;
  padding: 0.5rem 0.75rem;
  border: 1px solid #000;
  background-color: #49DEB2;
  color: #000;
  box-shadow: 1px 1px 2px #052e1438;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s;
}

.button:focus {
  outline: none;
}
a.dl-button:hover{
  text-decoration:none
}

.button:hover,
.button:active {
  background: transparent;
  transition: 0.2s;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #FF2E4C;
  color: #000;
  border-color: #000;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.85rem;
}

.button--big {
  font-size: 1.5rem;
}

.button--icon{
  display:flex;  
  align-items: center;
}
.button--icon svg{
  margin-right:0.2rem;
}