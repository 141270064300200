.Dashboard{
    width:90%;
    max-width:480px;
    margin:auto;
    display:flex;
    flex-direction:column;
}
.dash-container{
    padding:0.15rem;
    
    margin:1rem 0;
    flex:1 0 100%;
    display:flex;
    border:3px solid;
    border-image: 
    linear-gradient( 
        to top right, 
      #1E2A78, 
      #FF2E4C
    ) 1 1;
}

.controls{
   
}
.controls div{
 
}
.action-container{
    display:flex;
}
.dash-form{
    flex: 1 0 100%;
    display:flex;
    flex-direction:column;
}
.Dashboard h2{
    text-align:left;
    font-size: 3.5rem;
    line-height:0;
    margin:2rem 0;
}
.controls__sub{
}
.controls__search{
    display:flex;
}

.controls__search input{
        width:100%;
        padding:1rem ;
        font-size:1.3rem;
        font-weight:bold;
        border:1px solid #000;
}
.controls__search input::placeholder{
   color:#000;
   font-weight:lighter;
    
}
.controls__buttons{
    
    display:flex;
    flex-wrap:wrap;
}
.controls__buttons button{
    flex:1 0 50%;
    padding:1rem 0;
    border:1px solid #000;
}

.controls__buttons button:hover{
   box-shadow: inset 1px 1px 5px #000 
}

#settings-container{
    z-index: 100;
    height:125px;
    width:175px;
    background-color: rgba(0, 0, 0, 0.684);
    border: 1px solid #ff2e4d24;
    position:absolute;
    display:flex;
    align-items:flex-start;
    top:1rem;
    left:1rem;
}

#settings-container button{
    flex:1;
    background-color: transparent;
    color: #ff2e4d24;
    border:0;
    border-bottom:1px solid #ff2e4d24;
}

#settings-container button:hover{
    color: #FF2E4C;
    transition:0.8s;
    cursor:pointer;
    }

.overview-container{
   width:100%;
   max-height:300px;
   overflow-y:scroll;
}    
.overview-container ul{
    display: flex;
    width:100%;
    flex-direction: column;
    padding:0;
    margin:0;
    list-style-type: none;
}    
.overview-container ul li{
    display:flex;
    margin:0;
    width:100%;
    height:4rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #000;
}
.overview-container ul li span{
    padding:0 1rem;
    margin:0 auto;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.overview-container ul li img{
    display:block;
    max-height:4rem;
    max-width:6rem;
    width:100%;
    height:100%;
    object-fit: cover;
}
