.authentication{
    width:80%;
    max-width:480px;
    margin:5rem auto auto;
    padding:2rem;
    background-color: #00000f;
    border-radius: 0;
    color: #fff;
    border:3px solid;
    border-image: 
    linear-gradient( 
        to top right, 
      #1E2A78, 
      #FF2E4C
    ) 1 1;
}
.logo-container{
    width:50px;
    margin:1rem auto;
}
.logo-container img{
    display: inline-block;
    width:100%;
    height:100%;
}